<template>
  <div class="col-md-12 p-0">
    <b-card>
      <b-row v-if="item">
        <b-col>
          <h3 class="font-weight-bolder">
            <b-link class="btn btn-outline-primary" to="/market">
              <FeatherIcon icon="ChevronLeftIcon"></FeatherIcon>
              {{ $t("back") }}
            </b-link>
            {{ $t("invoice_id") }} : I-{{ item.id }}
          </h3>
        </b-col>
        <b-col align="end">
          <!-- <InvoiceStatus :status="item.status" /> -->
        </b-col>
      </b-row>
    </b-card>

    <form-wizard
      color="#1586c4"
      :title="null"
      :subtitle="null"
      shape="square"
      v-model.trim="tab"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="mb-3"
      @on-complete="submit"
    >
      <template slot="step" scope="props">
        <wizard-step
          :tab="props.tab"
          :transition="props.transition"
          :key="props.tab.title"
          :index="props.index"
        >
        </wizard-step>
      </template>
      <template slot="footer" slot-scope="props" align="end">
        <div
          class="col-md-12 mb-2 justify-end text-end align-end text-danger"
          align="end"
          v-if="buyerError"
        >
          {{ $t(buyerError) }}
        </div>
        <div class="d-flex justify-content-between col-md-12 pr-0">
          <div>
            <b-button
              @click="cancelButton(props)"
              v-if="props.activeTabIndex != 0"
              size="md"
              variant="outline-primary"
              class="mr-1 mb-2"
            >
              <feather-icon icon="ArrowLeftIcon" size="14" />
              <span class="pb-1" v-if="props.activeTabIndex == 2">
                {{ $t("go_back_to_market") }}</span
              >
              <span class="pb-1" v-else> {{ $t("cancel") }}</span>
            </b-button>
          </div>
          <div align="end" v-if="props.activeTabIndex != 2">
            <!-- <b-button
              disabled
              size="md"
              variant="outline-primary"
              class="mr-1 mb-1"
            >
              <feather-icon icon="MessageCircleIcon" size="14" />
              {{ $t("ask_a_question") }}
            </b-button> -->
            <b-button
              v-if="props.activeTabIndex == 0 && initialFractions != 100"
              size="md"
              @click="checkForBalance(props)"
              class="mb-1"
              variant="primary"
              id="buyInvoice"
              :disabled="loader"
            >
              <feather-icon icon="ArrowRightCircleIcon" size="14" />
              {{ $t("buy") }}
            </b-button>
            <div v-if="loader && props.activeTabIndex == 1 && initialFractions != 100" class="row justify-content-center"><img width="50" height="50" src="/new-loader.svg" /></div>
            <b-button
              v-if="props.activeTabIndex == 1 && initialFractions != 100"
              size="md"
              @click="openModal(props)"
              class="mb-1"
              variant="primary"
              id="buyInvoice"
              :disabled="disableButton"
            >
              <feather-icon icon="ArrowRightCircleIcon" size="14" />
              {{ $t("buy") }}
            </b-button>
          </div>
        </div>
      </template>
      <!-- invoice details tab -->
      <tab-content :title="$t('invoice_details')">
        <invoice-details
          :invoice="item"
          :statistics="supportTrackerRadialBar"
          :ratingStatus="ratingStatus"
        />
      </tab-content>
      <!-- invest tab -->
      <tab-content :title="$t('invest')"
        ><invest
          :invoice="item"
          @setSlider="setSlider"
          @disableBuy="disableBuy"
          @modalValues="modalValues"
        />

        <b-modal
          id="bv-modal-example"
          centered
          hide-footer
          :title="$t('buy_definitely')"
        >
          <b-card-text class="text-left">
            {{ $t("agree_with_purchase_of_the_invoice") }}

            <span>
            <strong> <a  @click="toTerms()">{{ $t("agree_with_purchase_of_the_invoice_link") }}</a></strong>

            </span>
            <span v-if="$store.state.app.language == 'de'">
              {{ $t("agree_with_purchase_of_the_invoice_after_link") }}
            </span>

            <div class="mt-2">
              {{ $t("buy_invoice_modal_subtitle") }}
            </div>

            <!--<div class="mt-2 font-weight-bolder">-->
              <!--{{ $t("investment_amount") }}:-->
              <!--<span class="ml-2 font-weight-normal">-->
                    <!--{{ amountFormat(buyModalInfo.amount, buyModalInfo.currency) }}-->
              <!--</span>-->
            <!--</div>-->
            <!--<div class="mb-2 font-weight-bolder">-->
              <!--{{ $t("expected_net_return") }}:-->
              <!--<span class="ml-2 font-weight-normal">-->
                 <!--{{ buyModalInfo.currency }} {{ amountFormat(buyModalInfo.net_return, buyModalInfo.currency) }}-->
             <!--</span>-->
            <!--</div>-->
            <!--<hr />-->
            <!--<div class="mb-3 font-weight-bolder">-->
              <!--{{ $t("total") }}:-->
              <!--<span class="ml-2 font-weight-normal">-->
                  <!--{{ buyModalInfo.currency }} {{ amountFormat( Number(buyModalInfo.amount) + Number(buyModalInfo.net_return), buyModalInfo.currency ) }}-->
              <!--</span>-->
            <!--</div>-->
          </b-card-text>

          <b-row>
            <b-col class="text-center">
              <b-button
                variant="danger"
                @click="$bvModal.hide('bv-modal-example')"
                class="mr-2"
              >
                {{ $t("no") }}
              </b-button>
              <b-button variant="primary" @click="callApproveModalAction()">
                {{ $t("yes") }}
              </b-button>
            </b-col>
          </b-row>
        </b-modal>
      </tab-content>

      <!-- confirm tab  -->
      <tab-content :title="$t('confirmation')">
        <h1 class="">
          <Confirmation
            :slider="slider"
            :invoiceData="invoiceData"
          ></Confirmation>
        </h1>
      </tab-content>
    </form-wizard>

    <b-row class="mb-0" v-if="item && (activeTab == 0 || activeTab == 2)">
      <b-col md="12">
        <b-card class="card-transaction" no-body>
          <b-card-body class="row">
            <div class="col-xl-4 col-lg-4 col-md-6 mb-1 mt-1">
              <b-media no-body>
                <b-media-body>
                  <!--<h6 class="transaction-title font-weight-bolder font-1rem">-->
                    <!--{{ $t("market") }}:-->

                    <!--<span class="font-weight-normal ml-1 font-1rem"-->
                      <!--&gt;Switzerland</span-->
                    <!--&gt;-->
                  <!--</h6>-->

                  <!-- <h6 v-if="item.icon">{{ item.des }}</h6> -->

                    <h6 class="transaction-title font-weight-bolder font-1rem">
                        {{ $t("assets_class") }}:

                        <span class="font-weight-normal ml-1 font-1rem"
                        >Invoice Receivables</span
                        >
                    </h6>

                </b-media-body>
              </b-media>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 mb-1 mt-1">
              <b-media no-body>
                <b-media-body>

                    <h6 class="transaction-title font-weight-bolder font-1rem">

                        {{ $t('marketplace_origin_title') }}:

                        <span class="font-weight-normal ml-1 font-1rem">
                            {{ $t('seller') }} ( {{ invoiceData }} ) {{ $t('marketplace_origin_text1') }} 111
                        </span>

                    </h6>

                </b-media-body>
              </b-media>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 mb-1 mt-1">
              <b-media no-body>
                <b-media-body>
                  <h6 class="transaction-title font-weight-bolder font-1rem">
                    {{ $t("currency") }}:

                    <span class="font-weight-normal ml-1 font-1rem">{{item.currency.name}}</span>
                  </h6>
                </b-media-body>
              </b-media>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 mb-1 mt-1">
              <b-media no-body>
                <b-media-body>
                  <h6 class="transaction-title font-weight-bolder font-1rem">
                    {{ $t("factoring_type") }}:
                    <span class="font-weight-normal ml-1 font-1rem">

                      {{
                          item && item.recourse == 0
                            ? $t("Open")
                            : $t("Silent")
                        }}
                      <feather-icon
                        icon="HelpCircleIcon"
                        role="button"
                        v-b-tooltip.hover
                        :title="$t('silent_tooltip')"
                        size="18"
                      />
                    </span>
                    <span class="font-weight-normal ml-1 font-1rem">
                      <span>
                        {{
                          item && item.is_reverse == 1 ? $t("Reverse") : (item && item.recourse == 0

                            ? $t("Non Recourse")
                            : $t("Recourse"))
                        }}
                      </span>
                      <feather-icon
                        icon="HelpCircleIcon"
                        role="button"
                        v-b-tooltip.hover
                        :title="$t('recourse_tooltip')"
                        size="18"
                        v-if="!(item && item.is_reverse == 1)"
                      />
                    </span>
                  </h6>
                </b-media-body>
              </b-media>
            </div>


            <div
              class="col-xl-4 col-lg-4 col-md-6 mb-1 mt-1"
              v-if="item && item.collateral"
            >
              <b-media no-body>
                <b-media-body>
                  <h6 class="transaction-title font-weight-bolder font-1rem">
                    {{ $t("collateral(1)") }}:

                    <span class="font-weight-normal ml-1 font-1rem">{{
                      $t("intermediar")
                    }}</span>
                  </h6>
                </b-media-body>
              </b-media>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-6 mb-1 mt-1 font-1rem"
              v-if="item && item.collateral"
            >
              <b-media no-body>
                <b-media-body>
                  <h6 class="transaction-title font-weight-bolder font-1rem">
                    {{ $t("collateral_value_remaining") }}:
                    <span class="font-weight-normal ml-1 font-1rem">120%</span>
                  </h6>
                </b-media-body>
              </b-media>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-card
      class=""
      v-if="
        activeTab < 2 && item && item.fractions && item.fractions.length > 0
      "
    >
      <div class="col-md-12 mt-1 pl-0 mr-1">
        <div align="start" class="font-weight-bolder text--gray mb-2">
          {{ $t("fractions_bought") }}
        </div>

        <b-table
          striped
          id="fraction-table"
          :items="item.fractions"
          :fields="fractionFields"
          responsive
        >
          <template #head()="scope">
            <div class="text-nowrap">{{ $t(scope.label) }}</div>
          </template>
          <template #cell(currency)="">
            <span>CHF</span>
          </template>
          <template #cell(buyer)="data">
            <span v-if="data.item.company && data.item.company.name">{{
              data.item.company.name
            }}</span>
            <span
              v-else-if="data.item.company.user && data.item.company.user[0]"
              >{{ data.item.company.user[0].name }}
              {{ data.item.company.user[0].last_name }}</span
            >
          </template>
           <template #cell(invoice_id)="data">
            <span v-if="data" style="width:55px;" class="badge badge-light-primary"> I-{{ data.value }} </span>
            <span v-else>-</span>
          </template>


          <template #cell(fraction)="data">
            <span v-if="data.item.fraction"> {{ data.item.fraction }}% </span>
            <span v-else>-</span>
          </template>
          <template #cell(amount)="data">
            <span v-if="data.item.amount">
              {{ data.item.amount | formatAmount }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(discount)="data">
            <span v-if="data.item.discount">
              {{ data.item.discount | formatAmount }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(hive_fee)="data">
            <span v-if="data.item">
              {{ data.item.hive_fee | formatAmount }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(net_return)="data">
            <span v-if="data.item">
              {{ data.item.net_return | formatAmount }}
            </span>
            <span v-else>-</span>
          </template>

          <template #cell(created_at)="data">
            <span v-if="data.item.created_at">{{
              data.item.created_at | formatDateMinutes
            }}</span>
            <span v-else>-</span>
          </template>

          <template #cell(status)="data">
            <span
              v-if="
                data.item.transactions &&
                data.item.transactions[0] && ( data.item.transactions[0].company_id == $store.state.auth.user.company_id)

              "
            >
            {{ $t("purchased") }}
            </span>
            <span v-else-if=" data.item.transactions &&
            data.item.transactions[0] && ( data.item.transactions[0].company_id != $store.state.auth.user.company_id)
          "> {{ $t("sold") }}</span>
          <span v-else>-</span>
          </template>
        </b-table>
      </div>
    </b-card>
  </div>
</template>
<script>
import { FormWizard, TabContent, WizardStep } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { required, email } from "@validations";
import InvoiceDetails from "./InvoiceDetails.vue";
import Invest from "./Invest.vue";
import Confirmation from "./Confirmation.vue";
import InvoiceStatus from "@/components/invoices/InvoiceStatus";
import i18n from "@/libs/i18n";
import { $themeColors } from "@themeConfig";
import { CurrencyAmountFormat } from '@core/utils/CurrencyAmountFormat';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    InvoiceStatus,
    InvoiceDetails,
    Invest,
    Confirmation,
    WizardStep,
  },
  data() {
    return {
      loader: false,
      item: null,
      tab: 0,
      slider: 0,
      initialFractions: 0,
      invoiceData: [],
      supportTrackerRadialBar: {},
      ratingStatus: "",
      hidetext: false,
      activeTab: 0,
      buyerError: "",
      checkAmount: "",
      checkFunds: "",
      modalText: "",
      modalTitle: "",
      approveModalAction: null,
      myProps: null,
      modal: false,
      disableButton: true,
      buyModalInfo: {
        amount: "",
        fee: "",
        total: "",
        net_return: "",
      },
      fractionFields: [
        {
          label: "invoice_id",
          align: "start",
          key: "invoice_id",
          thClass: "bg-light-primary text-dark",
        },
        {
          label: "datetime",
          align: "start",
          key: "created_at",
          thClass: "bg-light-primary text-dark",
        },
        {
          label: "fraction",
          align: "start",
          key: "fraction",
          sortable: false,
          thClass: "bg-light-primary text-dark",
        },

        {
          label: "amount",
          align: "right",
          key: "amount",
          sortable: false,
          thClass: "bg-light-primary text-dark text-right",
          tdClass: "text-right",
        },

        {
          label: "status",
          key: "status",
          customSlot: true,
          sortable: false,

          thClass: "bg-light-primary text-dark",
        },
      ],
    };
  },
  created() {
    this.getInvoice();
  },
  methods: {

        amountFormat(amount, currency) {
          return CurrencyAmountFormat(amount, currency)
        },

    toTerms(){
      if(this.$i18n.locale == "en"){
        window.open("https://www.aequitex.com/terms-condition", "_blank");
      }
      else{
        window.open("https://www.aequitex.com/de/bedingungen-und-konditionen", "_blank");
      }
      },
    calcTotal(amount, fee) {
      let formatAmount = amount.replace("");
    },
    modalValues(amount, fee, net_return, currency) {
      this.buyModalInfo = {
        amount: amount,
        fee: fee,
        net_return: net_return,
        currency: currency

      };
      console.log('buymodalinfo',this.buyModalInfo)
    },
    disableBuy(butt) {
      if (butt != undefined) {
        this.disableButton = butt;
      }
    },
    changeModalInfo(props) {
      this.props = props;
      this.modal = true;
    },
    openModal(props) {
      if (this.slider) {
        this.$bvModal.show("bv-modal-example");
        this.myProps = props;
      } else {
        this.$swal({
          position: "center",
          icon: "error",
          title: "choose_how_much_%_you_want_to_buy",
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
      }
    },
    callApproveModalAction() {
      this.buyInvoice(this.myProps);
    },

    cancelButton(props) {
      this.buyerError = "";
      if (props.activeTabIndex == 2) {
        this.$router.push("/market");
        this.activeTab = 2;
      } else if (props.activeTabIndex == 1) {
        props.prevTab();
        this.activeTab = 0;
      } else {
        props.prevTab();
        this.activeTab = 1;
        // this.$router.push("/market");
      }
    },
    setSlider(slider) {
      this.slider = slider;
    },
    checkForBalance(props) {
      this.buyerError = "";
      props.nextTab();
      this.hidetext = true;
      this.tab = 2;
      this.activeTab = 2;
    },
    buyInvoice(props) {
      this.loader = true;
      this.$bvModal.hide("bv-modal-example");
      if (this.slider) {
        let data = localStorage.getItem("invoice_data");
        if (data) {
          data = JSON.parse(data);
          this.buyerError = "";
          if (this.twoDecimalNumber(data[0]) <= this.twoDecimalNumber(data[1])) {
            let parsed = this.slider.replace("%", "");
            let finalValue = Number(parsed);

            let data = {
              fraction: finalValue - this.initialFractions,
            };
            this.$http
              .post(`/market/invoice/${this.$route.params.id}/buy`, data)
              .then((res) => {
                if (res) {

                  console.log(res);

                  this.loader = false;
                  this.hidetext = true;
                  this.activeTab = 3;
                  this.invoiceData = [
                    {
                      text: "invoice",
                      value: 'I-' + this.item.id,
                    },
                    {
                      text: "seller",
                      value: 'C-' + this.item.company_id,
                    },
                    {
                      text: "debtor",
                      value:'C-' + this.item.debtor.id,
                    },
                    {
                      text: "investment_amount",
                      value: "CHF " + res.data.value.bought.amount,
                    },
                    {
                      text: "expected_net_return",
                      value: "CHF " + res.data.value.bought.net_return,
                    },
                  ];
                  if (finalValue != 0) {
                    props.nextTab();
                  }
                }
              });
          } else {
            this.$swal({
              position: "center",
              icon: "error",
              title: this.$t("you_dont_have_enough_budget"),
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
            this.loader = false;
          }
        }
      } else {
        this.$swal({
          position: "center",
          icon: "error",
          title: "choose_how_much_%_you_want_to_buy",
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        this.loader = false;
      }
    },
    goNext(slider, amount, funds) {
      this.checkAmount = amount;
      this.checkFunds = funds;
      document.getElementById("buyInvoice").click();
    },
    changeTab(tab) {
      tab.activeTabIndex++;
      this.tab++;
    },
    getInvoice() {
      this.$http
        .post(`/market/invoice/${this.$route.params.id}`)
        .then((res) => {
          if (res) {

            this.item = res.data.value;
              let doc = document.querySelectorAll(".wizard-nav-pills > li > a ");

            doc.forEach((el) => {
              el.href = "#";
            });
            if (this.item) {
              this.item.fractions.map((el) => {
                this.initialFractions += el.fraction;
              });
              if (this.item.flash_sale == 0) {
                this.flashSale = false;
              } else {
                this.flashSale = true;
              }

              if (this.item.recourse == 0) {
                this.recourse = 0;
              } else {
                this.recourse = 1;
              }
              if (this.item.due_date == null || this.item.amount == "0.00") {
                this.disabledFields = false;
              } else {
                this.disabledFields = true;
              }
              this.load = false;
              if (this.item.flash_sale == 0) {
                this.flashSale = false;
              } else {
                this.flashSale = true;
              }

              if (
                this.item.scorings &&
                this.item.scorings.length &&
                this.item.scorings[0].rating
              ) {
                let rating = this.item.scorings[0].rating;
                if (
                  rating == "AAA" ||
                  rating == "AA+" ||
                  rating == "AA" ||
                  rating == "AA-"
                ) {
                  this.ratingStatus = "high_grade";
                }
                if (rating == "A" || rating == "A+" || rating == "A-") {
                  this.ratingStatus = "medium_grade";
                }
                if (rating == "BBB" || rating == "BBB+" || rating == "BBB-") {
                  this.ratingStatus = "lower_medium_grade";
                }

                if (rating == "BB" || rating == "BB+" || rating == "BB-") {
                  this.ratingStatus = "speculative";
                }
                if (rating == "B" || rating == "B+" || rating == "B-") {
                  this.ratingStatus = "highly_speculative";
                }
                if (rating == "CCC+" || rating == "CCC") {
                  this.ratingStatus = "substantial_risk_or_default";
                }

                if (rating == "CCC-" || rating == "CC" || rating == "D") {
                  this.ratingStatus = "default_with_little_recovery";
                }
              }
              this.supportTrackerRadialBar = {
                series: [this.item.weight_score],
                chartOptions: {
                  plotOptions: {
                    radialBar: {
                      size: 250,
                      offsetY: 20,
                      startAngle: -150,
                      endAngle: 150,
                      hollow: {
                        size: "60%",
                      },
                      track: {
                        background: "#fff",
                        strokeWidth: "100%",
                      },
                      dataLabels: {
                        show: true,
                        name: {
                          offsetY: 10,
                          color: "#5e5873",
                          fontSize: "2.3rem",
                          show: true,
                        },
                        value: {
                          offsetY: 15,
                          color: "#5e5873",
                          fontSize: "3rem",
                          show: false,
                        },
                      },
                    },
                  },
                  colors: [$themeColors.success],
                  fill: {
                    type: "gradient",
                    gradient: {
                      shade: "dark",
                      type: "horizontal",
                      shadeIntensity: 0.5,
                      gradientToColors: [$themeColors.primary],
                      inverseColors: true,
                      opacityFrom: 1,
                      opacityTo: 1,
                      stops: [0, 100],
                    },
                  },
                  stroke: {
                    dashArray: 8,
                  },
                  labels: [
                    this.item.scorings && this.item.scorings.length > 0
                      ? this.item.scorings[0].rating
                      : "",
                  ],
                },
              };
            }
          }
        });
    },
    submit() {},
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
</style>

